"use strict";
// @ts-ignore
const Swiper = window.Swiper;
const navigation = {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
};
if (document.querySelector('.product-swiper')) {
    // let smallSlider = document.querySelector('.product-calculator_small-slider')
    let thumbs = null;
    if (document.querySelector('.product-thumbs-swiper')) {
        thumbs = new Swiper('.product-thumbs-swiper', {
            // slidesPerView: smallSlider ? 4 : 4.8,
            slidesPerView: 'auto',
            spaceBetween: 16,
        });
    }
    let swiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 12,
        pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            renderCustom: function (_swiper, current, total) {
                let out = '';
                for (let i = 1; i < total + 1; i++) {
                    if (i == current) {
                        out = `${out}<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex=${i} role="button" aria-label="Go to slide ${i}1"></span>`;
                    }
                    else {
                        out = `${out}<span class="swiper-pagination-bullet" tabindex=${i} role="button" aria-label="Go to slide ${i}1"></span>`;
                    }
                }
                return out;
            },
            clickable: true,
        },
        navigation,
    };
    if (thumbs) {
        // @ts-ignore
        swiperOptions.thumbs = {
            swiper: thumbs
        };
    }
    // @ts-ignore
    new Swiper('.product-swiper', swiperOptions);
}
